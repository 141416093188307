<template>
  <div>
    <b-row>
      <b-col cols="2">
        <b-list-group>
          <b-list-group-item
              v-for="group in Object.keys(sop_groups)"
              :key="group"
              @click="loadGroup(group)"
              href="#"
              :active="focus === group">
            {{ group }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="10">
        <b-card v-if="sop === null">
          <b-card-title><b-link :href="sop_group_urls[focus]"><b-icon icon="github"></b-icon></b-link>{{ focus }}</b-card-title>
          <b-card-body>
            <b-list-group>
              <b-list-group-item href="#" v-for="sop in focus_sops" :key="sop.path" @click="loadSOP(sop)"> {{ fmtName(sop.name) }}</b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
        <b-card v-else>
          <b-card-title>
            <b-link :href="sop.html_url" target="_blank"><b-icon icon="github"></b-icon></b-link>
          </b-card-title>
          <b-card-body>
            <div v-html="sops[sop.name]"></div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SOPHome",
  props: [
      'loggedIn',
  ],
  data: function () {
    return {
      sop_groups: {},
      sop_group_urls: {},
      sops: {},
      focus: '',
      sop: null,
      sop_html: '',
      cfg: {
        auth: {
          username: process.env.VUE_APP_GH_USER,
          password: process.env.VUE_APP_GH_PAT,
        }
      },
    };
  },
  methods: {
    fmtName(sop_name) {
      const regex = /(?<key>[x0-9]+) - (?<name>.*)\.md/;
      let match_groups = sop_name.match(regex).groups
      if(match_groups === undefined) {
        return sop_name;
      } else {
        return match_groups.key + ' - ' + match_groups.name;
      }
    },
    loadGroup(group) {
      this.focus = group;
      this.sop = null;
    },
    loadSOP(sop) {
      this.sop = sop;
      if (this.sops[sop.name] === undefined) {
        const loader = this.$loading.show();
        axios.get(sop.url, {
          auth: this.cfg.auth,
          headers: {
            'Accept': 'application/vnd.github.html',  // Tells github to return rendered html instead of markdown
          },
        })
            .then((resp) => {
              console.log(resp.headers);
              this.sops[sop.name] = resp.data;
              this.sops = Object.assign({}, this.sops);
            })
            .finally(() => {
              loader.hide();
            })
      }
    },
  },
  computed: {
    focus_sops() {
      if ((this.sop_groups[this.focus]||null) !== null) {
        const filtered = [];
        this.sop_groups[this.focus].forEach((obj) => {
          if (obj.type === 'file') {
            filtered.push(obj);
          }
        });
        filtered.sort((l, r) => {
          return l.name > r.name;
        })
        return filtered;
      }
      return [];
    }
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
  mounted() {
    const loader = this.$loading.show();
    let sop_groups = {};
    let sop_group_urls = {};
    axios.get(process.env.VUE_APP_GH_URL, this.cfg)
    .then((resp) => {
      let promises = [];
      resp.data.forEach((obj) => {
        if (obj.type === "dir") {
          promises.push(
              axios.get(obj.url, this.cfg)
              .then((resp2) => {
                sop_groups[obj.name] = resp2.data;
                sop_group_urls[obj.name] = obj.html_url;
              })
          );
        }
        Promise.all(promises)
        .then(() => {
          this.focus = Object.keys(this.sop_groups)[0];
          this.sop = null;
          // Next 2 lines needed to make new fields of these objects reactive
          this.sop_groups = Object.assign({}, this.sop_groups, sop_groups);
          this.sop_group_urls = Object.assign({}, this.sop_group_urls, sop_group_urls);
          loader.hide();
        })
      })
    })
    .catch((err) => {
      console.log(err.response);
    });
  }
}
</script>

<style>

img {
  max-width: 100%;
}

</style>